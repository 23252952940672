import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Public, ViewList} from '@mui/icons-material'
import {ToggleButtonGroup, ToggleButton, SvgIconProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PLANT_VIEWS, PlantViewOption, PlantViewOptions} from '../../consts'
import {getTranslationKey} from '../../utils'

type PlantViewToggleProps = {
  value: PlantViewOption
  onChange: (value: PlantViewOption) => void
  translationPrefix?: string
  fullWidth?: boolean
}

const PlantViewButtonIcon: React.FC<SvgIconProps & {plantView: PlantViewOption}> = ({
  plantView,
  ...props
}) => {
  if (plantView === PlantViewOptions.REGIONAL_STRUCTURE) return <Public {...props} />
  if (plantView === PlantViewOptions.PLANT_LIST) return <ViewList {...props} />
  return null
}

export const PlantViewToggle: React.FC<PlantViewToggleProps> = ({
  value,
  onChange,
  translationPrefix,
  fullWidth
}) => {
  const {t} = useTranslation()
  const onValueChanged = (
    event: React.MouseEvent<HTMLElement>,
    plantView: PlantViewOption | null
  ) => {
    if (plantView) {
      onChange(plantView)
    }
  }

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={value}
      onChange={onValueChanged}
      aria-label={t(getTranslationKey('plantViewToggle.ariaLabelToggle', translationPrefix))}
      sx={{
        border: 1,
        borderColor: customThemeConstants().palette.mediumInputBorder
      }}
      fullWidth={fullWidth}
    >
      {PLANT_VIEWS.map((plantView) => (
        <ToggleButton
          sx={(theme) => ({
            '.MuiToggleButtonGroup-grouped&:not(.Mui-selected)': {
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.text.primary
              }
            }
          })}
          key={plantView}
          value={plantView}
          data-test-id={`plants-view-toggle-btn-${plantView}`}
        >
          <PlantViewButtonIcon plantView={plantView} />
          <Typography variant="button" ml={1}>
            {t(getTranslationKey(`plantViewToggle.${plantView}`, translationPrefix))}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
