import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantSelectOption} from '../../hproduce/types'
import {getTranslationKey} from '../../utils'

import {LocationSelectDropdown} from './LocationSelectDropdown'

type Props = {
  plants: PlantSelectOption[]
  translationPrefix?: string
}

export const PlantSelectDropdown: React.FC<Props> = ({plants, translationPrefix}) => {
  const {t} = useTranslation()

  return (
    <LocationSelectDropdown
      label={t(getTranslationKey('plantSelection.selectPlant', translationPrefix))}
      options={plants.map(({plantId, plantName, url}) => ({id: plantId, name: plantName, url}))}
      dataTestId="plant-select-dropdown"
    />
  )
}
