import {DeletionModal, EditDeleteMoreButton} from '@hconnect/uikit/src/lib2'
import React, {useState, useRef, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

import {CommentEditDialog} from './CommentEditDialog'
import {CommonComment, EditCommentProps, DeleteCommentProps} from './comments.types'

interface CommentMoreButtonProps {
  comment: CommonComment
  moreButtonTestId?: string
  deleteCommentProps: DeleteCommentProps
  editCommentProps: EditCommentProps
  translationPrefix?: string
}

export const CommentMoreButton: React.FC<CommentMoreButtonProps> = ({
  comment,
  moreButtonTestId,
  deleteCommentProps,
  editCommentProps,
  translationPrefix
}) => {
  const {t} = useTranslation()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const commentToDelete = useRef<CommonComment>()

  const onDeleteClicked = useCallback((item: CommonComment) => {
    commentToDelete.current = item
    setDeleteDialogOpen(true)
  }, [])

  const onDeleteConfirmed = useCallback(() => {
    if (commentToDelete.current?.id) {
      deleteCommentProps.onDelete(commentToDelete.current.id)
    }
    setDeleteDialogOpen(false)
  }, [deleteCommentProps])

  const onDeleteCancelled = useCallback(() => {
    setDeleteDialogOpen(false)
    commentToDelete.current = undefined
  }, [])

  return (
    <>
      <EditDeleteMoreButton<CommonComment>
        loading={deleteCommentProps.deletingInProgress}
        item={comment}
        onEdit={() => {
          setEditDialogOpen(true)
        }}
        onDelete={onDeleteClicked}
        data-test-id={moreButtonTestId}
      />
      <DeletionModal
        open={deleteDialogOpen}
        message={t(getTranslationKey('comments.deletionDialog.message', translationPrefix))}
        cancelButtonCaption={t(getTranslationKey('button.cancel', translationPrefix))}
        cancelButtonTestId={'comment-delete-cancel-btn'}
        confirmButtonTestId={'comment-delete-confirm-btn'}
        confirmButtonCaption={t(getTranslationKey('button.delete', translationPrefix))}
        onConfirm={onDeleteConfirmed}
        onCancel={onDeleteCancelled}
      />
      <CommentEditDialog
        open={editDialogOpen}
        handleOpen={setEditDialogOpen}
        comment={comment}
        editCommentProps={editCommentProps}
        translationPrefix={translationPrefix}
      />
    </>
  )
}
