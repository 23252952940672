import {Add} from '@mui/icons-material'
import {Box, CircularProgress, Divider, IconButton, Typography} from '@mui/material'
import {trim} from 'lodash'
import React, {ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

import {CommentForm} from './CommentForm'
import {CommentItem} from './CommentItem'
import {CommonComment, EditCommentProps, DeleteCommentProps} from './comments.types'

type CommentsProps<T extends CommonComment> = {
  isLoading: boolean
  onAddComment?: (text: string) => void
  timeZone: string
  data?: T[]
  deleteCommentProps?: DeleteCommentProps
  editCommentProps?: EditCommentProps
  renderPrefix?: (commentId: string) => ReactNode | undefined
  hideDivider?: boolean
  translationPrefix?: string
  dateFormat?: string
  showCounter?: boolean
  showHeader?: boolean
  showAdd?: boolean
  customCommentPostfix?: (comment: T) => ReactNode
}

export const Comments = <T extends CommonComment>({
  data,
  isLoading,
  onAddComment,
  deleteCommentProps,
  editCommentProps,
  renderPrefix,
  timeZone,
  hideDivider,
  translationPrefix,
  showCounter,
  showHeader = true,
  showAdd = true,
  dateFormat,
  customCommentPostfix
}: CommentsProps<T>) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Box mt={showHeader || showAdd ? 3 : 0} data-test-id="comments-box">
      {(showHeader || showAdd) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {showHeader && (
            <Typography variant="button" data-test-id="comments-header">
              {t(getTranslationKey('comments.heading', translationPrefix))}
              {showCounter && !!data?.length && ` (${data.length})`}
            </Typography>
          )}
          {showAdd && (
            <IconButton
              component="span"
              data-test-id="comments-add-button"
              color="primary"
              aria-label={t(getTranslationKey('comments.button.ariaLabel', translationPrefix))}
              onClick={() => setOpen((op) => !op)}
            >
              {isLoading ? (
                <CircularProgress size={20} data-test-id="comments-loading-spinner" />
              ) : (
                <Add />
              )}
            </IconButton>
          )}
        </Box>
      )}
      {open && (
        <CommentForm
          onCancel={() => setOpen(false)}
          onSave={(value) => {
            setOpen(false)
            if (showAdd) {
              onAddComment!(trim(value))
            }
          }}
          translationPrefix={translationPrefix}
        />
      )}
      {data?.map((comment) => (
        <React.Fragment key={comment.id}>
          <CommentItem
            comment={comment}
            deleteCommentProps={deleteCommentProps}
            editCommentProps={editCommentProps}
            renderPrefix={renderPrefix}
            timeZone={timeZone}
            translationPrefix={translationPrefix}
            noTextGutter={hideDivider}
            dateFormat={dateFormat}
            customCommentPostfix={customCommentPostfix}
          />
          {!hideDivider && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  )
}
