import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Check} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {DialogTitle, DialogContent, Dialog, TextField} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import {trim} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

import {MAX_COMMENT_LENGTH} from './comments.consts'
import {CommonComment, EditCommentProps} from './comments.types'

interface CommentEditDialogProps {
  open: boolean
  handleOpen: (open: boolean) => void
  comment: CommonComment
  editCommentProps: EditCommentProps
  translationPrefix?: string
}

export const CommentEditDialog: React.FC<CommentEditDialogProps> = ({
  open,
  handleOpen,
  comment,
  editCommentProps,
  translationPrefix
}) => {
  const {t} = useTranslation()
  const [commentValue, setCommentValue] = useState(comment.text)
  const handleSubmit = () => {
    editCommentProps.onEdit(comment.id, trim(commentValue))
  }
  const onEnter = () => {
    setCommentValue(comment.text)
  }
  const handleClose = () => {
    handleOpen(false)
  }

  useEffect(() => {
    if (!editCommentProps.savingChangesInProgress) {
      handleOpen(false)
    }
  }, [editCommentProps.savingChangesInProgress, handleOpen])

  return (
    <Dialog fullWidth open={open} TransitionProps={{onEnter}} data-test-id="comment-edit-dialog">
      <DialogTitle>
        {t(getTranslationKey('comments.editDialog.title', translationPrefix))}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant={'filled'}
          label={t(getTranslationKey('comments.label.enterComment', translationPrefix))}
          multiline
          value={commentValue}
          inputProps={{
            maxLength: MAX_COMMENT_LENGTH,
            'data-test-id': 'comment-edit-dialog-comment-field'
          }}
          onChange={(event) => {
            setCommentValue(event.target.value)
          }}
          data-test-id={'comment-dialog-comment-field'}
        />
      </DialogContent>
      <DialogActions sx={{padding: 3}}>
        <CancelButton onClick={handleClose} data-test-id="comment-dialog-cancel-btn">
          {t(getTranslationKey('button.cancel', translationPrefix))}
        </CancelButton>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={!trim(commentValue) || trim(commentValue) === comment.text}
          startIcon={<Check />}
          data-test-id="comment-dialog-save-btn"
          loading={editCommentProps.savingChangesInProgress}
          loadingPosition={'start'}
        >
          {t(getTranslationKey('button.save', translationPrefix))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
